import React from 'react';

const me = [
  'rachel',
  'a maker',
  'a student',
  'a carpenter',
  'a problem solver',
  'a software engineer',
  'empathetic',
  'a bachatera',
  'dedicated',
  'loving',
  'a coffee lover',
  'a daughter',
  'a triathlete',
  'female',
  'motivated',
  'a leader',
  'athletic',
  'creative',
  'a Tottenham fan',
  'innovative',
  'a dessert lover',
  'a golfer',
  'passionate',
  'giggly',
  'a feminist',
  'an ex-goalkeeper',
  'confident',
  'thoughtful',
  'hard working',
  'analytical',
  'wanderlust',
  'endearing',
  'independent',
  'strong',
  'enthusiastic',
  'fun loving',
  'an energy giver',
  'respectful',
  'a bridge',
  'a breadwinner',
  'trustworthy',
];
export default class Hero extends React.Component {
  intervalID = 0;
  constructor(props) {
    super(props);
    this.state = { whoami: me[0], count: 0 };
  }
  wordChanger = () => {
    if (this.state.count < me.length - 1)
      this.setState((prevState) => ({ count: prevState.count + 1 }));
    else this.setState({ count: 0 });
    this.setState({ whoami: me[this.state.count] });
  };
  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.wordChanger();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    return (
      <section className="hero relative is-large md:min-h-screen flex flex-col justify-center items-center">
        <div className="hero-body flex justify-center items-center whoami ">
          <h1 className="font-sans text-4xl font-bold">i am </h1>
          <div className="line text-gray-500 font-normal">
            {' '}
            {this.state.whoami}
          </div>
          <span className="font-sans text-4xl font-bold"> .</span>
        </div>
        <div className="hero-arrow mt-10">&darr;</div>
      </section>
    );
  }
}
