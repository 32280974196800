import * as React from 'react';

import Hero from '../components/Hero';
import Layout from '../components/Layout';

const IndexPage = () => {
  return (
    <Layout>
      <div>
        <Hero />
        <section className="pt-48">
          <div className="container max-w-4xl mx-auto px-4">
            <div>
              <h2 className="font-headline font-bold text-3xl mb-4">
                Hello world. My name is Rachel Fryan.
              </h2>
              <h3 className="font-headline mb-6">
                I am a freelance software engineer & maker going to school for
                carpentry at{' '}
                <a
                  href="https://woodtech.seattlecentral.edu/programs/carpentry"
                  target="_blank"
                  rel="noreferrer"
                >
                  Seattle Central College's Wood Technology Center
                </a>{' '}
                .
              </h3>
              <div className="mb-10">
                <p className="mb-2">
                  As a software engineer focused on web development, I bring a
                  unique combination of problem-solving skills and a keen eye
                  for design to every project I work on. I am passionate about
                  tinkering and building, and I'm constantly curious about how
                  things work. My career drive is centered around finding and
                  implementing innovative solutions to problems using
                  technology, with the ultimate goal of bettering humanity.
                </p>
                <p className="mb-2">
                  My experience as a collegiate athlete has taught me how to
                  thrive in competitive, high-pressure situations. I am no
                  stranger to hard work and dedication, and I bring that same
                  level of commitment to my work as a software engineer. My
                  academic background in computer science and visual
                  communications, with a focus on multimedia, provides me with a
                  solid foundation of knowledge and skills. However, I am also a
                  lifelong learner, always seeking out new passions and
                  exploring new territories to stay at the forefront of my
                  field.
                </p>
                <p className="mb-2">
                  Overall, my unique combination of creativity, problem-solving
                  skills, and competitive drive make me a valuable asset to any
                  team. I am committed to delivering high-quality work and
                  finding innovative solutions to the most challenging problems.
                </p>
                <br />
                <p>Cheers. &#x1f37b;</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <img src="/img/mountain.jpeg" alt="PNW mountains" />
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
